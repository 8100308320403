import RadialProgress from '@/components/radial-progress'
import UpgradeButton from '@/components/upgrade-button'
import useActivePlan from '@/hooks/useActivePlan'
import { CreationBase, JobStatus } from '@/types'
import { cls, whisper } from '@/utils'
import IconClockTimeHistory from '@haiper/icons-svg/icons/outline/clock-time-history.svg'

interface PendingJobProps {
  creation: CreationBase
  jobStatus: JobStatus | null
  showMode: 'mobile' | 'grid' | 'single'
}

const PendingJob = ({ jobStatus, showMode, creation }: PendingJobProps) => {
  const { data: activePlan } = useActivePlan()
  const isFreePlan = Boolean(activePlan?.is_free || !activePlan)

  return (
    <div className='size-full relative border border-solid border-border rounded-lg flex justify-center items-center p-6 aspect-video'>
      <div className='w-60 min-w-60 md:w-105 h-45 max-h-full'>
        <div
          className={cls(
            'flex justify-center items-center flex-col bg-surface backdrop-blur-[10px] size-full relative rounded-lg',
            showMode === 'single' ? 'gap-4' : 'gap-2',
          )}
        >
          {jobStatus?.progress ? (
            <div className={cls('flex flex-col gap-2 justify-center items-center')}>
              <RadialProgress
                smooth
                size={showMode === 'single' ? 48 : 48}
                className={cls('text-heading-xs', showMode === 'single' ? 'size-12' : 'size-12')}
                progress={Math.round((jobStatus?.progress ?? 0) * 100) || 0}
                duration={10000}
              />
              <div
                className={cls(
                  'text-text',
                  showMode === 'single' ? 'text-body-lg tracking-32' : 'text-body-md tracking-15',
                )}
              >
                Your {creation.output_type === 'image' ? 'image' : 'video'} is being generated
              </div>
            </div>
          ) : (
            <div
              className={cls('flex flex-col items-center gap-2 justify-center', showMode === 'single' ? 'gap-4' : '')}
            >
              <IconClockTimeHistory
                className={cls('text-icon-subdued', showMode === 'single' ? 'size-12' : 'size-12')}
              />
              <div
                className={cls(
                  'text-text',
                  showMode === 'single' ? 'text-body-lg tracking-32' : 'text-body-md tracking-15',
                )}
              >
                Queuing for generation
              </div>
            </div>
          )}
          {isFreePlan && (
            <div className={cls('flex flex-col items-center gap-2', showMode === 'single' ? 'gap-4' : '')}>
              <div
                className={cls(
                  'text-text-subdued text-center',
                  showMode === 'single' ? 'text-body-md tracking-15' : 'text-body-sm',
                )}
              >
                Upgrade to Haiper Membership to create more tasks at once.
              </div>
              <UpgradeButton
                size={showMode === 'single' ? 'default' : 'sm'}
                source='pending-creation'
                className={showMode === 'single' ? 'h-10' : 'h-8'}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PendingJob
