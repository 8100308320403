import Video, { VideoApi } from '@/components/video'
import { DEFAULT_AVATAR, SOCIAL_ENTRIES } from '@/constants'
import useAmplitude from '@/hooks/useAmplitude'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import { PoNVoid, Spotlight } from '@/types'
// import IconShare from '@haiper/icons-svg/icons/outline/share.svg'
import IconChat from '@haiper/icons-svg/icons/outline/chat.svg'
import {
  ReactElement,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import Link from '@/components/link'
import Ellipsis from '../ellipsis'
import Button from '../button'
import { getShareOrigin, openNewTab, round2, utcDateTime, cls } from '@/utils'
import dayjs from 'dayjs'
import IconHeartOutline from '@haiper/icons-svg/icons/outline/heart.svg'
import IconHeartSolid from '@haiper/icons-svg/icons/solid/heart.svg'
import { likeSpotlight } from '@/service/spotlight.service'
import IconChevronRightSmall from '@haiper/icons-svg/icons/outline/chevron-right-small.svg'
// import ShareDialog from '@/components/interactions/share-dialog'
import { useSetAtom } from 'jotai'
import { loginDialogOpenAtom } from '@/atoms'
import { useInView } from 'react-intersection-observer'
import { Alert } from '@lemonsqueezy/wedges'
import IconClock from '@haiper/icons-svg/icons/outline/clock-time-history.svg'
import IconX from '@haiper/icons-svg/icons/outline/x-circle.svg'
import useAuth0Auth from '@/hooks/useAuth0Auth'
import { useRouter } from 'next/navigation'
import Image from '../image'

export interface SpotlightCardProps {
  className?: string
  inactive?: boolean
  data: Spotlight
  onClick?: () => PoNVoid
  onTouchMoveForward?: () => PoNVoid
  onTouchMoveBackward?: () => PoNVoid
  onVideoClick?: () => PoNVoid
  onShow?: (item: Spotlight) => PoNVoid
  autoPlay?: boolean
  mode?: 'page' | 'card'
  roundedClassName?: string
  playOnHover?: boolean
  checkReviewState?: boolean
}

export interface SpotlightCardRef {
  videoRef: RefObject<HTMLVideoElement>
  apiRef: RefObject<VideoApi>
}

const availableAspectRatios: Record<string, string> = {
  '21:9': cls('aspect-21/9'),
  '16:9': cls('aspect-16/9'),
  '4:3': cls('aspect-4/3'),
  '1:1': cls('aspect-1/1'),
  '3:4': cls('aspect-3/4'),
  '9:16': cls('aspect-9/16'),
}

const alertClassname = cls('absolute rounded-sm top-2 left-2 h-7 p-0 pointer-events-none z-20')

const status2AlertMap: Record<string, ReactElement> = {
  in_reviewed: (
    <Alert
      color='warning'
      title={
        <div className='flex gap-1 items-center bg-surface-caution text-text-caution dark:bg-surface-caution dark:text-text-caution'>
          <IconClock className='size-4 text-icon-caution dark:text-icon-caution' />
          <span className='text-text-caution dark:text-text-caution'>Being reviewed</span>
          {/* <IconInfo className='size-4 text-icon-caution dark:text-icon-caution' /> */}
        </div>
      }
      before={<div className='pl-0' />}
      className={cls(
        alertClassname,
        'bg-surface-caution text-text-caution dark:bg-surface-caution dark:text-text-caution',
      )}
    />
  ),
  unqualified: (
    <Alert
      color='error'
      title={
        <div className='flex gap-1 items-center bg-surface-critical text-text-critical dark:bg-surface-critical dark:text-text-critical'>
          <IconX className='size-4 text-icon-critical dark:text-icon-critical' />
          <span className='text-text-critical dark:text-text-critical'>Unqualified</span>
          {/* <IconInfo className='size-4 text-icon-critical dark:text-icon-critical' /> */}
        </div>
      }
      className={cls(
        alertClassname,
        'bg-surface-critical text-text-critical dark:bg-surface-critical dark:text-text-critical',
      )}
      before={<div className='pl-0' />}
    />
  ),
}

const SpotlightCard = forwardRef<SpotlightCardRef, SpotlightCardProps>(
  (
    {
      data,
      onVideoClick,
      onClick,
      onTouchMoveBackward,
      onTouchMoveForward,
      onShow,
      inactive,
      checkReviewState,
      className,
      autoPlay,
      playOnHover,
      mode,
      roundedClassName = 'rounded-[6px] md:rounded-[6px]',
    }: SpotlightCardProps,
    ref,
  ) => {
    const { isBelowMd } = useBreakpoint('md')
    const videoRef = useRef<HTMLVideoElement>(null)
    const router = useRouter()

    const handleOpenDetail = useCallback(() => {
      router.push(`/spotlight/${data?.spotlight_id}`)
    }, [router, data])

    const commentCount = data?.comment_num ?? 0

    const setLoginDialogOpen = useSetAtom(loginDialogOpenAtom)

    const { ref: inViewRef } = useInView({
      threshold: 0.75,
      triggerOnce: true,
      onChange(inView, entry) {
        if (inView) {
          onShow?.(data)
        }
      },
    })

    const showAuthDialog = useCallback(() => {
      setLoginDialogOpen(true)
    }, [setLoginDialogOpen])

    const [liked, setLiked] = useState(data?.commits?.is_like ?? false)
    const [likesCount, setLikesCount] = useState(data?.commits?.likes_count ?? 0)

    const touchPos = useRef({ x: 0, y: 0, lastTime: 0 })

    const showFullDescription = useMemo(() => {
      return mode === 'page'
    }, [mode])

    useEffect(() => {
      setLiked(data?.commits?.is_like ?? false)
      setLikesCount(data?.commits?.likes_count ?? 0)
    }, [data])

    const { track } = useAmplitude()

    const alert = status2AlertMap[data?.review_state ?? ''] ?? null
    const showInteractions = !checkReviewState || data?.review_state === 'published'

    const shareLink = useMemo(() => {
      if (!data?.spotlight_id) {
        return ''
      }
      const origin = getShareOrigin()
      return `${origin}/spotlight/${data?.spotlight_id}`
    }, [data])

    const trackEventParams = useMemo(() => {
      return {
        spotlight_id: data?.spotlight_id,
        spotlight_title: data?.title,
      }
    }, [data])

    const { isLogin } = useAuth0Auth()

    const handleToggleLikeStatus = useCallback(async () => {
      if (!isLogin) {
        showAuthDialog()
        return
      }
      const newStatus = !liked
      setLiked(newStatus)
      setLikesCount(newStatus ? (likesCount || 0) + 1 : Math.max(0, likesCount - 1))
      const eventName = newStatus ? 'click:spotlight:like' : 'click:spotlight:unlike'
      track(eventName, trackEventParams)

      try {
        await likeSpotlight({
          spotlight_id: data?.spotlight_id,
          is_like: newStatus,
        })
      } catch (error) {
        setLiked(!newStatus)
        setLikesCount(likesCount)
        throw error
      }
    }, [liked, data?.spotlight_id, likesCount, track, trackEventParams, isLogin, showAuthDialog])

    const videoApiRef = useRef<VideoApi>(null)

    useImperativeHandle(ref, () => ({
      videoRef,
      apiRef: videoApiRef,
    }))

    const playEventParamsRef = useRef({
      start_video_time: 0,
      start_video_percentage: 0,
      end_video_time: 0,
      end_video_percentage: 0,
      start_time: '',
      end_time: '',
      played_natural_duration: 0,
      played_video_duration: 0,
      total_duration: 0,
      played_percentage: 0,
      complete: false,
    })

    const resetPlayEventParams = useCallback(() => {
      playEventParamsRef.current = {
        start_video_time: 0,
        start_video_percentage: 0,
        end_video_time: 0,
        end_video_percentage: 0,
        start_time: '',
        end_time: '',
        played_natural_duration: 0,
        played_video_duration: 0,
        total_duration: 0,
        played_percentage: 0,
        complete: false,
      }
    }, [])

    const handlePlayStart = useCallback(() => {
      playEventParamsRef.current.start_time = utcDateTime()
      playEventParamsRef.current.start_video_time = round2(videoRef.current?.currentTime || 0)
      playEventParamsRef.current.start_video_percentage = videoRef.current?.duration
        ? round2(((videoRef.current?.currentTime || 0) / (videoRef.current?.duration || 1)) * 100)
        : 0
      playEventParamsRef.current.end_video_time = playEventParamsRef.current.start_video_time
      playEventParamsRef.current.end_time = ''
      playEventParamsRef.current.played_natural_duration = 0
      playEventParamsRef.current.played_video_duration = 0
      playEventParamsRef.current.total_duration = round2(videoRef.current?.duration || 0)
      playEventParamsRef.current.played_percentage = 0
      playEventParamsRef.current.complete = false
    }, [])

    const handlePlayPause = useCallback(() => {
      playEventParamsRef.current.total_duration = round2(videoRef.current?.duration || 0)
      if (!playEventParamsRef.current.start_time || !playEventParamsRef.current.total_duration) {
        return
      }
      const now = new Date()
      playEventParamsRef.current.end_time = utcDateTime(now)
      playEventParamsRef.current.end_video_percentage = videoRef.current?.duration
        ? round2(((videoRef.current?.currentTime || 0) / (videoRef.current?.duration || 1)) * 100)
        : 0
      playEventParamsRef.current.played_natural_duration = round2(
        Math.max(
          0,
          Math.min(
            dayjs(playEventParamsRef.current.end_time).diff(
              dayjs(playEventParamsRef.current.start_time),
              'milliseconds',
            ) / 1000,
            videoRef.current?.duration || 0,
          ),
        ),
      )
      if (playEventParamsRef.current.played_natural_duration <= 0) {
        return
      }

      playEventParamsRef.current.end_video_time = round2(videoRef.current?.currentTime || 0)
      playEventParamsRef.current.played_video_duration = round2(
        Math.max(
          0,
          Math.min(
            videoRef.current?.duration || 0,
            playEventParamsRef.current.end_video_time - playEventParamsRef.current.start_video_time,
          ),
        ),
      )

      playEventParamsRef.current.played_percentage =
        Math.round(
          (playEventParamsRef.current.played_video_duration / playEventParamsRef.current.total_duration) * 10000,
        ) / 100

      track('click:spotlight:play', {
        ...trackEventParams,
        ...playEventParamsRef.current,
      })
      resetPlayEventParams()
    }, [trackEventParams, track, resetPlayEventParams])

    const handleSocialEntryClick = useCallback(
      (social_account_type: string) => {
        track('click:spotlight:social-account', {
          ...trackEventParams,
          social_account_type,
        })
      },
      [track, trackEventParams],
    )

    useEffect(() => {
      return () => {
        handlePlayPause()
      }
    }, [handlePlayPause])

    const handleGotoAuthorProfile = useCallback(() => {
      track('click:spotlight:author', {
        ...trackEventParams,
        target_user_id: data?.user_id,
      })
      // open in new tab
      const url = `/profile/${data?.user_id}`
      openNewTab(url)
    }, [track, trackEventParams, data])

    const handlePlayEnded = useCallback(() => {
      playEventParamsRef.current.complete = true
      handlePlayPause()
    }, [handlePlayPause])

    const controlsProps = useMemo(() => {
      return {
        // hideProgress: true,
        className: roundedClassName,
      }
    }, [roundedClassName])

    const IconLike = liked ? IconHeartSolid : IconHeartOutline

    const [aspectRatioClassName, setAspectRatioClassName] = useState('aspect-video')

    const handleLoadedMetadata = useCallback(() => {
      const video = videoRef.current
      if (!video || !isBelowMd || mode !== 'page') {
        return
      }
      const { videoWidth, videoHeight } = video
      const ratio = videoWidth / videoHeight
      const avilableStages = [
        { ratio: 16 / 9, name: '16:9' },
        { ratio: 4 / 3, name: '4:3' },
        { ratio: 1, name: '1:1' },
        { ratio: 3 / 4, name: '3:4' },
      ]

      const closestStage = avilableStages.reduce(
        (prev, curr) => (Math.abs(curr.ratio - ratio) < Math.abs(prev.ratio - ratio) ? curr : prev),
        avilableStages[0],
      )

      setAspectRatioClassName(availableAspectRatios[closestStage.name] ?? '')
    }, [isBelowMd, mode])

    return (
      <div
        ref={inViewRef}
        className={cls(
          'flex flex-col p-2 md:p-4 bg-surface rounded-xl gap-2 md:gap-3 border-2 border-b-4 relative',
          className,
        )}
        aria-label='spotlight-card'
        onTouchStart={(e) => {
          touchPos.current.x = e.touches[0].clientX
          touchPos.current.y = e.touches[0].clientY
        }}
        onTouchMove={(e) => {
          const x = e.touches[0].clientX
          const y = e.touches[0].clientY
          const deltaX = x - touchPos.current.x
          const deltaY = y - touchPos.current.y

          const threshold = 50
          const cooldown = 1000
          if (Math.abs(deltaY) > Math.abs(deltaX) || touchPos.current.lastTime + cooldown > Date.now()) {
            return
          }
          if (deltaX > threshold) {
            touchPos.current.lastTime = Date.now()
            onTouchMoveBackward?.()
          } else if (-deltaX > threshold) {
            touchPos.current.lastTime = Date.now()
            onTouchMoveForward?.()
          }
        }}
        onClick={onClick}
      >
        <div
          className={cls(
            'flex flex-col gap-2 md:gap-3 size-full h-full',
            inactive ? 'pointer-events-none' : '',
            mode === 'page' ? 'gap-4 md:gap-4' : '',
          )}
        >
          <div
            className={cls('bg-surface-on-video rounded-md w-auto relative h-0 flex-1', aspectRatioClassName)}
            aria-label='video container'
          >
            <Video
              ref={videoRef}
              playsInline
              playOnHover
              controls={false}
              // blurBg
              api={videoApiRef}
              className={roundedClassName}
              roundedClassName={roundedClassName}
              containerClassName={cls(aspectRatioClassName)}
              maskClassName='border'
              playClassName='pointer-events-none'
              autoPlay={autoPlay}
              src={data?.stream_url || data?.video_url || ''}
              poster={data?.thumbnail_url}
              preload='metadata'
              muted={autoPlay ? true : undefined}
              controlsProps={controlsProps}
              // playOnHover={playOnHover}
              onClick={onVideoClick}
              onPlay={handlePlayStart}
              onPause={handlePlayPause}
              onEnded={handlePlayEnded}
              onLoadedMetadata={handleLoadedMetadata}
            />
            {alert}
          </div>
          <div className='w-full flex items-center justify-between overflow-x-hidden'>
            <div className='flex flex-1 justify-start gap-6 flex-wrap w-0 overflow-x-hidden' aria-label='author info'>
              <div
                className='flex gap-2.5 items-center flex-1 md:flex-none cursor-pointer p-0.5 pr-1 rounded-md hover:bg-surface-hover'
                aria-label='author basic info'
                onClick={handleGotoAuthorProfile}
              >
                <Image src={data?.avatar || DEFAULT_AVATAR} alt='avatar' className='size-6 rounded-full object-cover' />
                <Ellipsis
                  className='text-text text-body-md flex-1 flex md:hidden'
                  text={data?.username ?? ''}
                  ellipsis=''
                />
                <div className='text-text text-body-md truncate flex-1 hidden md:flex'>{data?.username ?? ''}</div>
              </div>
              <div className='gap-4 items-center hidden' aria-label='author social links'>
                {SOCIAL_ENTRIES.map((entry) => {
                  const { name, Icon } = entry
                  const accounts: Record<string, string> = data?.social_accounts ?? {}
                  return accounts[name] ? (
                    <Link
                      key={name}
                      className='p-1 rounded-md hover:bg-surface-hover'
                      href={accounts[name]}
                      target='_blank'
                      rel='noopener noreferer'
                      onClick={() => handleSocialEntryClick(name)}
                    >
                      <Icon className='size-5 cursor-pointer text-icon-subdued' />
                    </Link>
                  ) : null
                })}
              </div>
            </div>
            <div
              className={cls(
                'flex items-center justify-end gap-1 md:gap-2 min-w-max',
                showInteractions ? '' : 'hidden',
              )}
              aria-label='interactions'
            >
              <div className='flex items-center gap-1 md:gap-2' aria-label='thumbs'>
                <Button
                  variant='outline'
                  className='rounded-full p-2 md:px-3 h-8 md:h-10'
                  onClick={handleToggleLikeStatus}
                >
                  <div className='flex items-center justify-center gap-1 text-body-md'>
                    <IconLike
                      className={cls(
                        'size-5 md:size-6 min-w-5 md:min-w-6 text-icon',
                        // liked ? 'text-surface-critical-strong' : '',
                        liked ? 'text-border-critical-hover' : '',
                      )}
                    />
                    {likesCount ? <span className='text-icon text-left px-1'>{likesCount}</span> : null}
                  </div>
                </Button>
                <Button variant='outline' className='rounded-full p-2 md:px-3 h-8 md:h-10' onClick={handleOpenDetail}>
                  <div className='flex items-center justify-center gap-1 text-body-md'>
                    <IconChat className={cls('size-5 md:size-6 min-w-5 md:min-w-6 text-icon')} />
                    {commentCount ? <span className='text-icon text-left px-1'>{commentCount}</span> : null}
                  </div>
                </Button>
              </div>
              {/* <ShareDialog title='Share the spotlight' link={shareLink} trackEventParams={trackEventParams}>
                <Button
                  variant='outline'
                  aria-label='share'
                  className={cls('rounded-full p-2 md:px-3 h-8 md:h-10 hidden')}
                >
                  <IconShare aria-label='share' className='size-5 md:size-6 text-icon' />
                </Button>
              </ShareDialog> */}
            </div>
          </div>
          <div className='flex gap-4 items-center md:hidden justify-start w-full' aria-label='author social links'>
            {SOCIAL_ENTRIES.map((entry) => {
              const { name, Icon } = entry
              const accounts: Record<string, string> = data?.social_accounts ?? {}
              return accounts[name] ? (
                <Link
                  key={name}
                  className='p-1 rounded-md hover:bg-surface-hover'
                  href={accounts[name]}
                  target='_blank'
                  rel='noopener noreferer'
                  onClick={() => handleSocialEntryClick(name)}
                >
                  <Icon className='size-5 cursor-pointer text-icon-subdued' />
                </Link>
              ) : null
            })}
          </div>
          <div className={cls('flex flex-col w-full gap-1', mode === 'page' ? 'gap-4' : '')}>
            <div className='text-heading-lg font-bold tracking-45 truncate' aria-label='title'>
              <span className=''>{data?.title}</span>
            </div>
            <div className='flex gap-2 text-body-md tracking-15' aria-label='description'>
              {showFullDescription ? (
                <div className='whitespace-pre-line text-body-md tracking-15'>{data?.description ?? ''}</div>
              ) : (
                <div className='flex w-full'>
                  <Ellipsis className='w-full' text={data?.description ?? null} ellipsis='' />
                  <Link
                    href={`/spotlight/${data?.spotlight_id}`}
                    className={cls(
                      'text-text-interactive text-body-md tracking-15 hover:no-underline hover:opacity-80',
                      showInteractions ? '' : 'hidden',
                    )}
                  >
                    <div className='flex'>
                      <span>Details</span>
                      <IconChevronRightSmall className='size-5 text-icon-interactive' />
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  },
)

SpotlightCard.displayName = 'SpotlightCard'

export default SpotlightCard
